export const dataErfs = [
	{
		// This is an "li" element.
		title: "Erfs or Stands",
		to: "/erfs",
		menu: "Erfs",
		"menu-level": "1",
		icon: "",
		children: "",
	},
];
